import $ from "jquery";
import "slick-carousel";

//REVIEW CODE: it is giving error in the editor when more than one slider is added to the same page.

$(document).ready(initMainSlider);
if (window.acf) {
//  window.acf.addAction("render_block_preview/type=slider", initMainSlider);
}

function initMainSlider() {
  if ($(".js-slider").length) {

    $(".js-slider").slick({
      arrows: true,
      dots: false,
      fade: true,
      cssEase: "linear",
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
    });
  }
}
